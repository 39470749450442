const en = {
  link: 'Link Wallet',
  participants: 'The current number of participants',
  lottery: 'Lottery',
  recomment: 'Please enter the recommender',
  total: 'Total USDT',
  contract: 'The current contract',
  gameAward: 'Available game rewards',
  recommentAward: 'Available recommend rewards',
  available: 'Available ',
  rewards: 'rewards',
  bind: 'Bind',
  introduction: 'Game Introduction',
  detail: 'this is detail this is detail this is detail this is detail this is detail this is detail this is detail this is detail this is detail this is detail this is detail this is detail this is detail this is detail',
  list: 'Winners of the previous round',
  first: 'First Prize',
  second: 'Second Prize',
  third: 'Third Prize',
  balance: ' Balance',
  receive: 'receive'
}

export default en;