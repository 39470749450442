const zh = {
  link: '连接钱包',
  participants: '当前参与人数',
  lottery: '抽奖',
  recomment: '请输入推荐人',
  total: 'USDT总额',
  contract: '当前合约',
  gameAward: '当前可领取的游戏奖励',
  recommentAward: '当前可领取的推荐奖励',
  available: '当前可领取的 ',
  rewards: ' ',
  bind: '绑定',
  introduction: '游戏介绍',
  detail: '这个是游戏规则内容哈哈哈哈这个是游戏规则内容啦啦啦啦这个是游戏规则内容嗯嗯嗯嗯嗯嗯嗯这个是游戏规则内容哈哈哈哈这个是游戏规则内容啦啦啦啦这个是游戏规则内容嗯嗯嗯嗯嗯嗯嗯这个是游戏规则内容哈哈哈哈这个是游戏规则内容啦啦啦啦这个是游戏规则内容嗯嗯嗯嗯嗯嗯嗯',
  list: '上一轮获奖名单',
  first: '一等奖',
  second: '二等奖',
  third: '三等奖',
  balance: ' 余额',
  receive: '领取'
}

export default zh;